<template>
  <div>

    <p v-if="errors.length">
      <b>Status:</b>
      <ul>
        <li v-for="error in errors" :key="error">{{ error }}</li>
      </ul>
    </p>

    <form @submit.stop.prevent="onSubmit" class="form">
     <div class="card-body">
      <h3 class="font-size-lg text-dark font-weight-bold mb-6">1. Informações Básicas:</h3>
      <div class="mb-15">
      	<div class="form-group row">
  <label class="col-lg-3 col-form-label">id</label>
  <div class="col-lg-6">
    <div class="input-group">
      <div class="input-group-prepend"><span class="input-group-text"><i class="la la-chain"></i></span></div>
      <input v-model="id" require type="text" class="form-control" placeholder="Loja"/>
    </div>
  </div>
</div>
	<div class="form-group row">
  <label class="col-lg-3 col-form-label">pla_title</label>
  <div class="col-lg-6">
    <div class="input-group">
      <div class="input-group-prepend"><span class="input-group-text"><i class="la la-chain"></i></span></div>
      <input v-model="pla_title" require type="text" class="form-control" placeholder="Loja"/>
    </div>
  </div>
</div>
	<div class="form-group row">
  <label class="col-lg-3 col-form-label">pla_subtitle</label>
  <div class="col-lg-6">
    <div class="input-group">
      <div class="input-group-prepend"><span class="input-group-text"><i class="la la-chain"></i></span></div>
      <input v-model="pla_subtitle" require type="text" class="form-control" placeholder="Loja"/>
    </div>
  </div>
</div>
	<div class="form-group row">
  <label class="col-lg-3 col-form-label">pla_price</label>
  <div class="col-lg-6">
    <div class="input-group">
      <div class="input-group-prepend"><span class="input-group-text"><i class="la la-chain"></i></span></div>
      <input v-model="pla_price" require type="text" class="form-control" placeholder="Loja"/>
    </div>
  </div>
</div>
	<div class="form-group row">
  <label class="col-lg-3 col-form-label">pla_discount_month</label>
  <div class="col-lg-6">
    <div class="input-group">
      <div class="input-group-prepend"><span class="input-group-text"><i class="la la-chain"></i></span></div>
      <input v-model="pla_discount_month" require type="text" class="form-control" placeholder="Loja"/>
    </div>
  </div>
</div>
	<div class="form-group row">
  <label class="col-lg-3 col-form-label">pla_discount_year</label>
  <div class="col-lg-6">
    <div class="input-group">
      <div class="input-group-prepend"><span class="input-group-text"><i class="la la-chain"></i></span></div>
      <input v-model="pla_discount_year" require type="text" class="form-control" placeholder="Loja"/>
    </div>
  </div>
</div>
	<div class="form-group row">
  <label class="col-lg-3 col-form-label">pla_info_data</label>
  <div class="col-lg-6">
    <div class="input-group">
      <div class="input-group-prepend"><span class="input-group-text"><i class="la la-chain"></i></span></div>
      <input v-model="pla_info_data" require type="text" class="form-control" placeholder="Loja"/>
    </div>
  </div>
</div>
	<div class="form-group row">
  <label class="col-lg-3 col-form-label">pla_text</label>
  <div class="col-lg-6">
    <div class="input-group">
      <div class="input-group-prepend"><span class="input-group-text"><i class="la la-chain"></i></span></div>
      <input v-model="pla_text" require type="text" class="form-control" placeholder="Loja"/>
    </div>
  </div>
</div>
	<div class="form-group row">
  <label class="col-lg-3 col-form-label">pla_active</label>
  <div class="col-lg-6">
    <div class="input-group">
      <div class="input-group-prepend"><span class="input-group-text"><i class="la la-chain"></i></span></div>
      <input v-model="pla_active" require type="text" class="form-control" placeholder="Loja"/>
    </div>
  </div>
</div>
	<div class="form-group row">
  <label class="col-lg-3 col-form-label">created</label>
  <div class="col-lg-6">
    <div class="input-group">
      <div class="input-group-prepend"><span class="input-group-text"><i class="la la-chain"></i></span></div>
      <input v-model="created" require type="text" class="form-control" placeholder="Loja"/>
    </div>
  </div>
</div>
	<div class="form-group row">
  <label class="col-lg-3 col-form-label">modified</label>
  <div class="col-lg-6">
    <div class="input-group">
      <div class="input-group-prepend"><span class="input-group-text"><i class="la la-chain"></i></span></div>
      <input v-model="modified" require type="text" class="form-control" placeholder="Loja"/>
    </div>
  </div>
</div>
	
     </div>
     <div class="card-footer">
      <div class="row">
       <div class="col-lg-3"></div>
       <div class="col-lg-6">
         <b-button v-if="$route.name == router_actions.route_new" type="submit" class="btn btn-success mr-2">Cadastrar</b-button>
         <b-button v-if="$route.name == router_actions.route_edit" type="submit" class="btn btn-success mr-2">Atualizar</b-button>
         <b-button type="reset" class="btn btn-secondary">Cancelar</b-button>
       </div>
      </div>
     </div>
     </div>
    </form>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
export default {
  name: "store",
  props: {
    id: String,
    router_actions: Object
  },
  mounted() {

    if(this.$route.name == this.router_actions.route_edit){
      let id = this.$route.params.id;
      ApiService.get('Plans/get')
        .then(({data}) =>{
          this.errors.push(data.message);
          this.id = data.plans[0].id
					this.pla_title = data.plans[0].pla_title
					this.pla_subtitle = data.plans[0].pla_subtitle
					this.pla_price = data.plans[0].pla_price
					this.pla_discount_month = data.plans[0].pla_discount_month
					this.pla_discount_year = data.plans[0].pla_discount_year
					this.pla_info_data = data.plans[0].pla_info_data
					this.pla_text = data.plans[0].pla_text
					this.pla_active = data.plans[0].pla_active
					this.created = data.plans[0].created
					this.modified = data.plans[0].modified
					
        })
          .catch(({response}) => {
            this.errors.push(response.data.errors);
          });
          
    }else{
      this.FormReset();
    }
  },
  components: {},
  data() {
    return {
			pla_title: '',
			pla_subtitle: '',
			pla_price: '',
			pla_discount_month: '',
			pla_discount_year: '',
			pla_info_data: '',
			pla_text: '',
			pla_active: '',
			created: '',
			modified: '',
			
      errors: []
    };
  },
  methods: {
    FormReset() {
      {{cols_reset}}
    },

    onSubmit(e) {
      let id = null;
      if(this.errors.length){
        this.message = [];
      }
      if(this.$route.name == this.router_actions.route_edit){
        id = this.$route.params.id;
      }

      ApiService.post('Plans/add', {
        id: this.id,
				pla_title: this.pla_title,
				pla_subtitle: this.pla_subtitle,
				pla_price: this.pla_price,
				pla_discount_month: this.pla_discount_month,
				pla_discount_year: this.pla_discount_year,
				pla_info_data: this.pla_info_data,
				pla_text: this.pla_text,
				pla_active: this.pla_active,
				created: this.created,
				modified: this.modified
				
      })
        .then(({data}) => {
          this.errors.push(data.message);
          this.FormReset();
        })
          .catch(({response}) => {
            this.errors.push(response.data.errors);
          });

      e.preventDefault();
    }
  },
};
</script>
